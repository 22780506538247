<template>
    <base-bottom-sheet v-model="isModalOpen" class="create-mollie-profile">
        <template #header>
            <bottom-sheet-header>
                {{ $t('integrations.mollie.createMollieProfileModal.title') }}
            </bottom-sheet-header>
        </template>
        <template #content>
            <v-form ref="form" v-model="valid" autocomplete="off">
                <v-row>
                    <v-col cols="12" md="6">
                        <text-field
                            v-model="profileData.name"
                            name="name"
                            :label="$t('general.name')"
                            outlined
                            dense
                            hide-details="auto"
                            type="text"
                            data-form="name"
                            :rules="requiredRules"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-field
                            v-model="profileData.email"
                            :label="$t('general.email')"
                            outlined
                            dense
                            hide-details="auto"
                            type="text"
                            data-form="email"
                            :rules="[...requiredRules, ...emailRules]"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-field
                            v-model="profileData.website"
                            :label="$t('general.website')"
                            outlined
                            name="website"
                            dense
                            hide-details="auto"
                            type="text"
                            data-form="website"
                            :hint="$t('general.websiteShouldStartWithHTTPProtocol')"
                            persistent-hint
                            :rules="[...requiredRules, ...urlRules]"
                        />
                    </v-col>
                    <v-col cols="12" md="6">
                        <text-field
                            v-model="profileData.phone"
                            v-mask="'+324########'"
                            name="phone"
                            :label="$t('general.phone')"
                            outlined
                            dense
                            hide-details="auto"
                            type="text"
                            data-form="phone"
                            :hint="$t('general.phoneStartsWithCountryCode')"
                            persistent-hint
                            :rules="[...requiredRules, ...validPhone]"
                        />
                    </v-col>

                    <v-col cols="12" md="12">
                        <submit-button
                            data-action="createMollieProfile"
                            color="primary"
                            :disabled="!valid"
                            @click="handleSubmit"
                        >
                            {{ $t('integrations.mollie.createMollieProfileModal.createProfile') }}
                        </submit-button>
                    </v-col>
                </v-row>
            </v-form>

            <loader v-if="loading" />
        </template>
    </base-bottom-sheet>
</template>

<script>
// --- State ---
// --- Components ---
import BottomSheetHeader from '@/components/BottomSheets/BottomSheetHeader';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';

// --- Helper ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';

import { notify } from '@/helpers/successNotification';
import SubmitButton from '@/components/SubmitButton.vue';
import { sendCreateMollieProfileRequest } from '@/services/integrations';

export default {
    components: {
        BottomSheetHeader,
        SubmitButton
    },

    mixins: [formRulesMixin],

    props: ['isOpen'],

    data() {
        return {
            valid: true,
            loading: false,
            profileData: {
                phone: '+324',
                email: null,
                website: 'https://',
                name: null
            }
        };
    },

    computed: {
        isModalOpen: {
            get() {
                return this.isOpen;
            },
            set(value) {
                this.$emit('update:isOpen', value);
            }
        }
    },

    methods: {
        async handleSubmit() {
            try {
                this.loading = true;

                await sendCreateMollieProfileRequest(this.profileData);

                notify.call(this, {
                    title: this.$t('settings.succesfuly_saved'),
                    text: this.$t('settings.succesfuly_saved')
                });

                this.isModalOpen = false;

                this.$emit('created-profile');
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
