<template>
    <div>
        <v-row>
            <v-col cols="12" md="12" sm="12">
                <div class="pb-3">
                    <v-stepper v-model="stepper" vertical>
                        <subscription-step-select-version
                            v-model="selectedTier"
                            :stepper="stepper"
                            :product-tiers="productTiers"
                            @next="moveToNextStep"
                        />

                        <subscription-step-frequency
                            v-model="subscriptionInterval"
                            :stepper="stepper"
                            :eligable-pricings="eligablePricingsForSelectedTier"
                            :can-edit="selectedTierComplete"
                            @next="moveToNextStep"
                        />

                        <subscription-step-select-payment-method
                            v-model="paymentMethodApplication"
                            :stepper="stepper"
                            :available-payment-methods="availablePaymentMethods"
                            :can-edit="selectedPlanComplete"
                        />
                    </v-stepper>
                    <v-row>
                        <v-col cols="12" class="text-center">
                            <submit-button
                                data-action="getSubscription"
                                :disabled="!canSubmit"
                                @click="handleUserChoseSubscription"
                                >{{ $t('general.purchase') }}</submit-button
                            >
                            <p class="mt-3">{{ $t('general.promocode_next_page') }}</p>
                            <template v-if="promocode">
                                <p class="small-text mt-3">
                                    {{ $t('subscription.promocode_entered_at_registration') }}:
                                </p>
                                <submit-button
                                    data-action="copy-publicurl-to-clipboard"
                                    color="primary"
                                    small
                                    @click="() => copyToClipboard(promocode.code)"
                                >
                                    <v-icon class="mr-1" x-small>mdi-clipboard-multiple-outline</v-icon
                                    >{{ promocode.code }}</submit-button
                                >
                            </template>
                        </v-col>
                    </v-row>
                </div>

                <div v-if="hasInvoices" class="py-3">
                    <v-card outlined class="mb-4 pa-8">
                        <h2>{{ $t('general.invoices') }}</h2>
                        <invoice-list :invoices="subscriptionInfo.invoices" />
                    </v-card></div
            ></v-col>
        </v-row>
    </div>
</template>

<script>
import { formRulesMixin } from '@/mixins/formRulesMixin.js';

// --- Error handling ---
import InvoiceList from '@/modules/subscription/presentation/components/InvoiceList.vue';

import productService from '../../domain/product.service';

import SubscriptionStepSelectPaymentMethod from './SubscriptionStepSelectPaymentMethod.vue';
import SubscriptionStepFrequency from './SubscriptionStepFrequency.vue';
import SubscriptionStepSelectVersion from './SubscriptionStepSelectVersion.vue';
import { toClipboard } from '@/helpers/general';

export default {
    components: {
        InvoiceList,
        SubscriptionStepSelectPaymentMethod,
        SubscriptionStepFrequency,
        SubscriptionStepSelectVersion
    },

    mixins: [formRulesMixin],
    props: ['plans', 'currentPaymentMethod', 'promocodes'],

    data() {
        return {
            subscriptionInterval: null,
            selectedTier: null,
            loading: false,
            stepper: 1,
            subscriptionInfo: null,
            paymentMethodApplication: null,
            paymentMethods: productService.getAllAvailablePaymentMethods()
        };
    },

    computed: {
        promocode() {
            if (this.promocodes.length === 0) {
                return null;
            }

            return this.promocodes[0];
        },
        eligablePricingsForSelectedTier() {
            return productService.eligablePricingsForSelectedTier(this.plans, this.selectedTier);
        },
        selectedPlanComplete() {
            return this.subscriptionInterval !== null;
        },
        selectedTierComplete() {
            return this.selectedTier !== null;
        },
        productTiers() {
            return productService.productTiers(this.plans);
        },
        availablePaymentMethods() {
            return productService.availablePaymentMethods(
                this.subscriptionInterval,
                this.paymentMethods,
                this.currentPaymentMethod
            );
        },
        hasInvoices() {
            return (
                this.subscriptionInfo &&
                this.subscriptionInfo.invoices &&
                Array.isArray(this.subscriptionInfo.invoices) &&
                this.subscriptionInfo.invoices.length > 0
            );
        },
        canSubmit() {
            return this.selectedTier && this.subscriptionInterval && this.paymentMethodApplication;
        }
    },

    methods: {
        copyToClipboard(text) {
            toClipboard.call(this, text);
        },
        moveToNextStep() {
            this.stepper = parseInt(this.stepper) + 1;
        },
        handleUserChoseSubscription() {
            this.$emit('choose-subscription', {
                selectedTier: this.selectedTier,
                subscriptionInterval: this.subscriptionInterval,
                paymentMethodApplication: this.paymentMethodApplication
            });
        }
    }
};
</script>
<style lang="scss" scoped></style>
