<template>
    <div>
        <template v-if="active">
            <v-simple-table v-if="mollieInformation">
                <template #default>
                    <tbody>
                        <tr>
                            <td>Mollie-connectie</td>
                            <td>
                                <submit-button small color="error" @click="deleteMollieConnection"
                                    >Verwijder Mollie-connectie</submit-button
                                >
                            </td>
                        </tr>
                        <tr>
                            <td>Basisgegevens in Mollie</td>
                            <td>
                                <submit-button
                                    v-if="!mollieInformation.profileId"
                                    small
                                    @click="openCreateMollieProfileModal"
                                    >Maak Mollie-profiel aan</submit-button
                                >
                                <template v-else>
                                    <template v-if="isVerified"> Geverifieerd! </template>
                                    <template v-else>
                                        Mollie is nog bezig met het verifiëren van je account. Kijk zeker eens in het
                                        dashboard of je misschien nog informatie moet aanleveren.
                                        <v-btn small icon @click="navigateToMollieDashboard"
                                            ><v-icon small>mdi-open-in-new</v-icon></v-btn
                                        >
                                    </template>
                                </template>
                            </td>
                        </tr>
                        <tr>
                            <td>Betalingslink inschakelen</td>
                            <td>
                                <submit-button
                                    :disabled="!mollieInformation.profileId || isVerified === false"
                                    small
                                    @click="togglePaymentLinks"
                                    >{{
                                        mollieInformation.enabledPaymentlinks
                                            ? 'Betalingslink uitschakelen'
                                            : 'Betalingslink aanzetten'
                                    }}</submit-button
                                >
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </template>
        <template v-else>
            <submit-button @click="redirectToMollieConnect">Connect to Mollie</submit-button>
        </template>

        <create-mollie-profile :is-open.sync="mollieProfileModal" @created-profile="handleCreatedProfile" />

        <loader v-if="loading" />
    </div>
</template>

<script>
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';

import {
    sendConnectMollieRequest,
    sendDeleteMollieConnection,
    sendGetMollieInformationRequest,
    sendUpdatePaymentlinksMollieConnection
} from '@/services/integrations';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';

import { notify } from '@/helpers/successNotification';
import SubmitButton from '@/components/SubmitButton.vue';
import Loader from '@/components/Loader.vue';
import CreateMollieProfile from './CreateMollieProfile.vue';
import { random } from 'lodash';
import { mapState } from 'vuex';

export default {
    components: { SubmitButton, Loader, CreateMollieProfile },

    mixins: [formRulesMixin],
    props: ['integrationId'],

    data() {
        return {
            loading: false,
            mollieInformation: null,
            mollieProfileModal: false
        };
    },

    computed: {
        ...mapState(['serverInfo']),
        active() {
            return this.mollieInformation != null;
        },
        isVerified() {
            return this.mollieInformation.verifiedStatus === 'verified';
        }
    },

    async created() {
        try {
            this.loading = true;

            await this.fetchMollieData();

            if (!this.mollieInformation) {
                if (this.$route.query.code) {
                    await sendConnectMollieRequest(this.$route.query.code, sessionStorage.getItem('state_mollie'));

                    notify.call(this, {
                        title: this.$t('integrations.connection_succesfuly_established'),
                        text: this.$t('integrations.connection_succesfuly_established')
                    });

                    this.$emit('updated-integration');

                    await this.fetchMollieData();
                }
            }
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
        } finally {
            this.loading = false;
        }
    },

    methods: {
        async fetchMollieData() {
            this.mollieInformation = await sendGetMollieInformationRequest();
        },
        openCreateMollieProfileModal() {
            this.mollieProfileModal = true;
        },
        async togglePaymentLinks() {
            try {
                this.loading = true;

                await sendUpdatePaymentlinksMollieConnection(!this.mollieInformation.enabledPaymentlinks);

                notify.call(this, {
                    title: this.$t('settings.succesfuly_updated'),
                    text: this.$t('settings.succesfuly_updated')
                });

                this.mollieInformation.enabledPaymentlinks = !this.mollieInformation.enabledPaymentlinks;

                this.$emit('updated-integration');
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },
        async handleCreatedProfile() {
            await this.fetchMollieData();
        },
        redirectToMollieConnect() {
            const state = `mollie_state_${random(0, 1_000_000)}`;
            sessionStorage.setItem('state_mollie', state);

            const permissions = [
                'organizations.read',
                'onboarding.write',
                'onboarding.read',
                'profiles.read',
                'profiles.write',
                'payment-links.write',
                'payments.read',
                'payment-links.read'
            ].join(' ');

            const redirectUrl = new URL(window.location).origin;

            const clientId = this.serverInfo.mollieClientId;

            window.location.href = `https://www.mollie.com/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUrl}/integrations&response_type=code&approval_prompt=force&scope=${permissions}&state=${state}`;
        },
        async deleteMollieConnection() {
            try {
                await sendDeleteMollieConnection(this.integrationId);

                notify.call(this, {
                    title: this.$t('general.succesfuly_deleted'),
                    text: this.$t('general.succesfuly_deleted')
                });

                this.$emit('updated-integration');

                await this.fetchMollieData();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            }
        },
        navigateToMollieDashboard() {
            window.open('https://www.mollie.com/dashboard/', '_blank').focus();
        }
    }
};
</script>

<style lang="scss" scoped></style>
