<template>
    <v-form v-if="taxes" v-model="valid">
        <v-row>
            <v-col cols="12">
                <v-select
                    v-model="taxes.selectedDisk"
                    data-field="annualIncomeAsEmployeeEuro"
                    :label="$t('general.annual_income')"
                    :items="taxRates"
                    item-text="label"
                    item-value="index"
                    outlined
                    clearable
                    persistent-hint
                    hide-details="auto"
                    hint
                />
            </v-col>
            <v-col cols="12">
                <tooltip
                    :underlined="true"
                    i18n-path="tooltips.settings.calculations.secondaryTotalOfMainOccupation.whyDoWeNeedIt"
                    ><template slot="activator">{{
                        $t('tooltips.settings.calculations.secondaryTotalOfMainOccupation.whyDoWeNeedIt.title')
                    }}</template></tooltip
                >
            </v-col>

            <v-col cols="12">
                <submit-button data-action="submit" :loading="loading" :disabled="!valid" @click="handleSubmit">
                    {{ $t('general.save') }}
                </submit-button>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import Tooltip from '@/components/Tooltip.vue';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { mapActions, mapState } from 'vuex';
import { convertToLocaleValuta } from '../../helpers/number-helpers/numberHelpers';
import { setSelectedDiskRequest } from '../../services/usersettings';

export default {
    components: {
        Tooltip
    },
    mixins: [formRulesMixin],

    data: function () {
        return {
            loading: false,
            valid: true,
            taxes: null,
            disks: []
        };
    },

    computed: {
        ...mapState(['selectedYear', 'globalSettingsStudent']),
        taxRates() {
            return this.globalSettingsStudent.taxRates.map((_rate, index) => ({
                label: `Je valt in belastingsschijf ${convertToLocaleValuta(_rate.lowerLimitEuro)}-${
                    _rate.upperLimitEuro ? convertToLocaleValuta(_rate.upperLimitEuro) : '...'
                } (belastingtarief: ${_rate.taxToPayPercentage}%)`,
                index
            }));
        }
    },

    async created() {
        try {
            this.startLoading();
            await this.loadTaxes();
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
        } finally {
            this.stopLoading();
            this.loading = false;
        }
    },

    methods: {
        ...mapActions(['startLoading', 'stopLoading']),
        ...mapActions('taxes', ['getTaxes']),
        async loadTaxes() {
            this.taxes = await this.getTaxes({
                year: this.selectedYear,
                force: true
            });
        },
        async handleSubmit() {
            this.loading = true;
            try {
                await setSelectedDiskRequest({
                    year: this.selectedYear,
                    selectedDisk: this.taxes.selectedDisk
                });

                notify.call(this, {
                    title: this.$t('settings.income_as_employee_succesfuly_saved'),
                    text: this.$t('settings.income_as_employee_succesfuly_saved')
                });

                await this.loadTaxes();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
