import { planTypeFrequencyStripe, intervalTypeRecurringStripe } from '../interfaces/index';

type IRecurring = {
    interval: intervalTypeRecurringStripe;
} | null;

export class Plan {
    private _id: string;
    private _type: planTypeFrequencyStripe;
    private _unit_amount: number;
    private _recurring: IRecurring;

    constructor(id: string, type: planTypeFrequencyStripe, unit_amount: number, recurring: IRecurring) {
        this._id = id;
        this._type = type;
        this._unit_amount = unit_amount;
        this._recurring = recurring;
    }

    isYearly(): boolean {
        if (!this._recurring) {
            return true;
        }

        return this._recurring.interval == 'year';
    }

    isMonthly(): boolean {
        return this._recurring?.interval == 'month';
    }

    isRecurringPlan(): boolean {
        return this._type === 'recurring';
    }

    isManualPlan(): boolean {
        return this._type === 'one_time';
    }

    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }

    public get type(): planTypeFrequencyStripe {
        return this._type;
    }
    public set type(value: planTypeFrequencyStripe) {
        this._type = value;
    }

    public get unit_amount(): number {
        return this._unit_amount;
    }
    public set unit_amount(value: number) {
        this._unit_amount = value;
    }

    public get recurring(): IRecurring {
        return this._recurring;
    }
    public set recurring(value: IRecurring) {
        this._recurring = value;
    }
}
