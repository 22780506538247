<template>
    <v-form id="invoicableForm" ref="form" v-model="valid">
        <v-row>
            <v-col cols="12">
                <text-field id="name" v-model="accountantEmailBCC.name" :label="$t('general.name')" outlined dense />
            </v-col>

            <v-col cols="12">
                <text-field
                    id="email"
                    v-model="accountantEmailBCC.email"
                    type="email"
                    :label="$t('general.email')"
                    outlined
                    dense
                    :rules="emailRules"
                />
            </v-col>

            <v-col cols="12">
                <submit-button :disabled="!valid" :loading="loading" @click="handleSubmit">
                    {{ $t('general.add') }}
                </submit-button>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { formRulesMixin } from '@/mixins/formRulesMixin.js';

export default {
    components: {},
    mixins: [formRulesMixin],
    props: ['loading'],

    data() {
        return {
            valid: false,
            accountantEmailBCC: {
                email: '',
                name: ''
            }
        };
    },

    methods: {
        async handleSubmit() {
            this.$emit('submit', this.accountantEmailBCC);
        }
    }
};
</script>
