import { dayjs } from '@/setup/dayjs';

type Invoice = {
    date: string;
};

export const getValidStripeInvoices = <T extends Invoice>(
    invoices: T[],
    latestDexxterInvoiceDate: string | null
): T[] => {
    if (!latestDexxterInvoiceDate) {
        return invoices;
    }

    return invoices.filter((_x) => {
        return dayjs(_x.date, 'YYYY-MM-DD').isAfter(dayjs(latestDexxterInvoiceDate, 'YYYY-MM-DD'));
    });
};

export const getCombinedInvoiceList = <T extends Invoice>({
    dexxterInvoices,
    stripeInvoices
}: {
    dexxterInvoices: T[];
    stripeInvoices: T[];
}): T[] => {
    const latestDxtInvoice = [...dexxterInvoices].sort((a, b) =>
        dayjs(a.date, 'YYYY-MM-DD').isSameOrBefore(dayjs(b.date, 'YYYY-MM-DD')) ? -1 : 1
    )[dexxterInvoices.length - 1];

    const stripeInvoicesAfterLatestDxtInvoice = getValidStripeInvoices(stripeInvoices, latestDxtInvoice?.date ?? null);

    return [...dexxterInvoices, ...stripeInvoicesAfterLatestDxtInvoice].sort((a, b) =>
        dayjs(a.date, 'YYYY-MM-DD').isAfter(dayjs(b.date, 'YYYY-MM-DD')) ? -1 : 1
    );
};
