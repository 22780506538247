export const invoicablePlacholders = [
    '#CLIENT_NAME#',
    '#CLIENT_EMAIL#',
    '#DOCUMENT_LINK#',
    '#TOTAL_INCL_VAT#',
    '#COMPANY_NAME#',
    '#DOCUMENT_NUMBER#',
    '#IBAN_NUMBER#',
    '#DUE_DATE#'
] as const;

export type invoicablePlacholderType = typeof invoicablePlacholders[number];
