
import AnnualIncomeForm from '@/components/Forms/AnnualIncomeForm.vue';
import ChangeSocialSecretariat from '@/components/Forms/ChangeSocialSecretariat.vue';
import ChangeStatutenForm from '@/components/Forms/ChangeStatutenForm.vue';
import PageHeader from '@/components/PageHeader.vue';
import ViewLayout from '@/components/ViewLayout.vue';
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

export default Vue.extend({
    components: {
        AnnualIncomeForm,
        ViewLayout,
        PageHeader,
        ChangeStatutenForm,
        ChangeSocialSecretariat
    },

    computed: {
        ...mapState(['selectedYear']),
        ...mapGetters('auth', ['isUserType'])
    },

    created() {
        this.getCurrentUserType();
    },

    methods: {
        ...mapActions('auth', ['getCurrentUserType'])
    }
});
