
// --- States ---
import { mapState, mapActions } from 'vuex';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import Vue from 'vue';
import { InitializedUseraccountAuthState } from '@/store/modules/auth';
import { MapStateToComputed } from '@/helpers/types';

export default Vue.extend({
    mixins: [formRulesMixin],
    data() {
        return {
            loading: false,
            valid: false,
            formData: {
                defaultPaymentDays: 0
            }
        };
    },
    computed: {
        ...(mapState('auth', ['currentUserData']) as MapStateToComputed<InitializedUseraccountAuthState>)
    },
    created() {
        this.formData.defaultPaymentDays = this.currentUserData.settings.defaultPaymentDays;
    },
    methods: {
        ...mapActions('auth', ['setDefaultPaymentDays']),

        async changeDefaultPaymentDays() {
            this.loading = true;
            try {
                await this.setDefaultPaymentDays(this.formData.defaultPaymentDays);
                notify.call(this, {
                    title: this.$t('settings.succesfuly_updated'),
                    text: this.$t('settings.succesfuly_updated')
                });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        }
    }
});
