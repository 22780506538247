<template>
    <div class="v-stepper-wrapper">
        <v-stepper-step :editable="canEdit" :complete="stepper > 2" step="2">
            <div class="TooltipTextWrapper">
                {{ $t('subscription.choose_frequency') }}
            </div>
        </v-stepper-step>
        <v-stepper-content step="2" :class="{ 'stepper-content-active': stepper == 2 }">
            <v-row>
                <v-col v-for="pricing of eligablePricings" :key="pricing.id">
                    <subscription-frequency-select :pricing="pricing" @choose="chooseFrequency" />
                </v-col>
            </v-row>
        </v-stepper-content>
    </div>
</template>

<script>
import SubscriptionFrequencySelect from './SubscriptionFrequencySelect.vue';

export default {
    components: {
        SubscriptionFrequencySelect
    },
    props: ['canEdit', 'stepper', 'eligablePricings', 'value'],

    methods: {
        chooseFrequency(frequency) {
            this.$emit('input', frequency);
            this.$emit('next');
        }
    }
};
</script>
<style lang="scss" scoped></style>
