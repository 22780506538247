<template>
    <div>
        <v-form ref="form" v-model="valid" @submit.prevent="handleSubmit">
            <v-row>
                <v-col cols="12" md="6">
                    <text-field
                        v-model="email"
                        :label="$t('settings.email_of_accountant')"
                        name="email"
                        data-id="accountant-email"
                        prepend-inner-icon="mdi-email"
                        type="email"
                        outlined
                        clearable
                        persistent-hint
                        required
                        :dense="dense"
                        :rules="emailRules"
                    />
                </v-col>
                <v-col cols="12">
                    <v-card-actions>
                        <v-spacer />
                        <submit-button
                            data-action="invite-accountant"
                            :disabled="!valid"
                            :loading="loading"
                            @click.prevent="handleSubmit"
                        >
                            {{ $t('settings.send_invitation') }}
                        </submit-button>
                    </v-card-actions>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
export default {
    mixins: [formRulesMixin],
    props: {
        loading: {
            type: Boolean,
            required: false
        },
        dense: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            valid: true,
            errorMessage: '',
            email: ''
        };
    },
    methods: {
        handleSubmit() {
            this.$emit('invite-accountant', this.email);
        },
        clearForm() {
            this.$refs.form.reset();
        }
    }
};
</script>
