<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                    <sub>
                        <tooltip :underlined="false" i18n-path="tooltips.settings.accountant.invite"
                            ><template slot="activator"> <v-icon small> mdi-information </v-icon></template></tooltip
                        >
                    </sub>
                </template>
            </page-header>
        </template>
        <template #content>
            <v-card outlined class="mb-4 pa-8">
                <div v-if="!userHasAccountant">
                    <h2 class="my-4">
                        {{ $t('settings.add_accountant') }}
                    </h2>
                    <accountant-invitation-form
                        ref="form"
                        :loading="loading"
                        dense
                        @invite-accountant="handleInviteAccountant"
                    />
                </div>

                <div v-else>
                    <h2>{{ $t('settings.your_accountant_is') }}:</h2>
                    <p class="ma-0">
                        {{ accountant.company.name }}
                    </p>

                    <p class="ma-0">
                        <span>{{ accountant.company.email }}</span>
                    </p>

                    <p v-if="accountant.company.phone" class="ma-0">
                        <span>{{ accountant.company.phone }}</span>
                    </p>
                    <p v-if="accountant.company.number" class="ma-0">
                        <span>{{ `BE${accountant.company.number}` }}</span>
                    </p>
                    <p v-if="accountant.company.ITAA" class="ma-0">
                        {{ `ITAA: ${accountant.company.ITAA}` }}
                    </p>

                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="error" text @click="deleteAccountant">
                            {{ $t('settings.remove_accountant') }}
                        </v-btn>
                    </v-card-actions>
                </div>
            </v-card>

            <confirmation-dialog
                v-if="showAccountantIsMigratedDialog"
                v-model="showAccountantIsMigratedDialog"
                data-id="register_invite_accountant"
                title="Deze adviseur gebruikt Dexxter 2.0"
                message="Wil je koppelen met deze adviseur, dan moeten we ook jouw profiel overzetten naar Dexxter 2.0. Geef ons via de support-knop bovenaan een seintje en we zetten je vliegensvlug over naar Dexxter 2.0. Uiteraard gaan al je gegevens netjes mee naar deze nieuwe versie."
                :positive-button="$t('general.confirm')"
                positive-color="green"
                @click-positive="showAccountantIsMigratedDialog = false"
                @click-negative="showAccountantIsMigratedDialog = false"
                @click-outside="showAccountantIsMigratedDialog = false"
            />

            <confirmation-dialog
                v-if="dialog"
                v-model="dialog"
                data-id="register_invite_accountant"
                :title="$t('general.register_invite_accountant')"
                :message="$t('general.text_register_invite_accountant')"
                :loading="loading"
                :negative-button="$t('general.cancel')"
                :positive-button="$t('general.confirm_funny')"
                :positive-color="'green'"
                :negative-color="'black'"
                @click-positive="sendRegisterInvitationAccountant"
                @click-negative="dialog = false"
                @click-outside="dialog = false"
            />
            <loader v-if="loading" />
        </template>
    </view-layout>
</template>

<script>
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
import AccountantInvitationForm from '@/components/Forms/accounting/AccountantInvitationForm.vue';
import Loader from '@/components/Loader';
import PageHeader from '@/components/PageHeader.vue';
import ViewLayout from '@/components/ViewLayout.vue';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import {
    deleteAccountantAccessToUserRequest,
    getAccountantOfUserRequest,
    inviteAccountantRequest
} from '@/services/accountants';

export default {
    components: {
        AccountantInvitationForm,
        Loader,
        ConfirmationDialog,
        ViewLayout,
        PageHeader
    },

    data() {
        return {
            loading: false,
            dialog: false,
            toInviteEmail: '',
            showAccountantIsMigratedDialog: false,
            accountant: undefined
        };
    },

    computed: {
        userHasAccountant() {
            return this.accountant;
        }
    },

    async created() {
        this.loading = true;
        await Promise.all([this.fetchAccountants()]).finally(() => {
            this.loading = false;
        });
    },

    methods: {
        async fetchAccountants() {
            this.accountant = await getAccountantOfUserRequest();
        },
        async handleInviteAccountant(email) {
            this.loading = true;

            try {
                this.showAccountantIsMigratedDialog = true;
            } catch (e) {
                if (e?.response?.status === 404) {
                    // Ask user to invite the accountant
                    this.dialog = true;
                    this.toInviteEmail = email;
                } else {
                    apiErrorAndDisplay.call(this, e);
                }
            } finally {
                this.loading = false;
            }
        },
        async deleteAccountant() {
            this.loading = true;

            try {
                await deleteAccountantAccessToUserRequest(this.accountant.id);
                this.fetchAccountants();

                notify.call(this, {
                    title: this.$t('general.accountant_removed_succesful'),
                    text: this.$t('general.accountant_removed_succesful'),
                    type: 'success'
                });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        },
        async sendRegisterInvitationAccountant() {
            if (!this.toInviteEmail) {
                throw new Error('E-mail ontbreekt');
            }
            try {
                this.loading = true;
                await inviteAccountantRequest(this.toInviteEmail);

                notify.call(this, {
                    title: this.$t('settings.invitation_send'),
                    text: this.$t('settings.invitation_send_more_info'),
                    type: 'success'
                });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
                this.dialog = false;
            }
        }
    }
};
</script>
