<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
            </page-header>
        </template>
        <template #content>
            <section v-if="!loading">
                <v-row>
                    <v-col cols="12">
                        <v-card outlined class="mb-4 pa-8">
                            <h2 class="my-4">{{ $t('settings.manage_notification') }}</h2>
                            <notification-settings-table />
                        </v-card>
                    </v-col>
                </v-row>
            </section>

            <loader v-if="loading" />
        </template>
    </view-layout>
</template>

<script>
import { mapActions } from 'vuex';

import Loader from '@/components/Loader';
// --- State ---

// --- Error handling ---
import NotificationSettingsTable from '@/components/DataTables/NotificationSettingsTable.vue';
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader.vue';

export default {
    components: {
        Loader,
        NotificationSettingsTable,
        ViewLayout,
        PageHeader
    },

    data() {
        return {
            loading: false,
            dialog: false
        };
    },
    methods: {
        ...mapActions('subscription', ['cancelSubscription'])
    }
};
</script>
