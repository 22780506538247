<template>
    <div>
        <view-layout>
            <template #header>
                <page-header>
                    <template #title>
                        {{ $t($route.meta.title) }}
                    </template>
                </page-header>
            </template>
            <template #content>
                <v-expansion-panels multiple>
                    <v-expansion-panel v-for="email in emails" :key="email.id">
                        <v-expansion-panel-header>Onderwerp: {{ email.subject }}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <email-card :email="email" class="mb-5" />
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>

                <div v-if="emails.length === 0" class="text-center">{{ $i18n.t('customerEmail.no_mails_send') }}</div>
            </template>
        </view-layout>
        <loader v-if="loading" />
    </div>
</template>

<script>
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
// --- Components ---
import Loader from '@/components/Loader';
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader';
import { getMails } from '@/services/email';

import { sortBy } from 'lodash';

import { capitalizeFirstLetter } from '../setup/filters';
import EmailCard from '@/components/EmailCard.vue';
import dayjs from 'dayjs';

export default {
    components: { Loader, ViewLayout, PageHeader, EmailCard },

    data() {
        return {
            loading: false,
            emails: []
        };
    },

    async created() {
        try {
            this.loading = true;
            this.emails = sortBy(
                await getMails(this.$route.params.id, capitalizeFirstLetter(this.$route.params.type)),
                (email) => dayjs(email.createdAt).valueOf()
            ).reverse();
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
        } finally {
            this.loading = false;
        }
    }
};
</script>
