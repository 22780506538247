type Feature = {
    name: string;
    disabled: boolean;
};

export function hasAccessToFeature(
    requestedFeaturesWhichMustBePresentOfUser: string[],
    featuresOfUser: Feature[]
): boolean {
    return requestedFeaturesWhichMustBePresentOfUser.every((_featureNeeded) => {
        const hasUserRequestedFeaturePermission = featuresOfUser
            .map((_feature) => _feature.name)
            .includes(_featureNeeded);
        if (!hasUserRequestedFeaturePermission) {
            return false;
        }

        // User has permissoin, now check if it is disabled
        const feature = featuresOfUser.find((_feature) => _feature.name === _featureNeeded);
        if (!feature) {
            throw new Error('Feature not found');
        }

        if (feature.disabled) {
            return false;
        }

        return true;
    });
}
