
// --- States ---
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { MapStateToComputed } from '@/helpers/types';
import { isVatLiable } from '@/helpers/VATHelper';
import { InitializedUseraccountAuthState } from '@/store/modules/auth';

export default Vue.extend({
    name: 'DefaultVATPercentageForm',

    data() {
        return {
            loading: false,
            vatPercentages: [0, 6, 12, 21],
            currentDefaultVATPercentage: 0
        };
    },

    computed: {
        ...(mapState('auth', ['currentUserData']) as MapStateToComputed<InitializedUseraccountAuthState>),
        isVATLiable(): boolean {
            return isVatLiable(this.currentUserData.settings.vatLiable);
        }
    },

    created() {
        this.currentDefaultVATPercentage = this.currentUserData.settings.defaultVATPercentage;
    },

    methods: {
        ...mapActions('auth', ['setDefaultVATPercentage']),

        async changeDefaultVATPercentage() {
            this.loading = true;
            try {
                await this.setDefaultVATPercentage(this.currentDefaultVATPercentage);
                notify.call(this, {
                    title: this.$t('settings.succesfuly_updated'),
                    text: this.$t('settings.succesfuly_updated')
                });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        }
    }
});
