<template>
    <div>
        <template v-if="!loading">
            <v-radio-group v-model="currentUserData.settings.invoiceTemplate" @change="changeInvoiceLayout">
                <div class="invoiceTemplate-wrapper">
                    <div v-for="template in invoiceTemplates" :key="template.id">
                        <div class="invoiceTemplate">
                            <div>
                                <img width="150px" :src="template.imageURL" @click="renderHTML(template.id)" />
                            </div>
                            <div class="d-flex justify-center">
                                <v-radio :value="template.id" :data-selection-id="`template-${template.id}`" />
                            </div>
                        </div>
                    </div>
                </div>
            </v-radio-group>

            <v-bottom-sheet v-model="showBottomSheet" transition="scale-transition" inset retain-focus scrollable eager>
                <v-card style="overflow-y: auto">
                    <div v-html="invoiceHTML"></div>
                </v-card>
            </v-bottom-sheet>
        </template>
        <loader-inline v-else />
    </div>
</template>

<script>
import LoaderInline from '@/components/LoaderInline.vue';
import { isUserAccount } from '@/helpers/auth';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { mapState } from 'vuex';

import { getInvoicetemplates } from '@/services/invoicetemplate';
import { previewInvoiceLayoutRequest, setInvoicelayoutRequest } from '@/services/usersettings';

export default {
    components: {
        LoaderInline
    },

    data() {
        return {
            loading: false,
            showBottomSheet: false,
            invoiceTemplates: null,
            invoiceHTML: null
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        canSeePreviewInvoice() {
            return isUserAccount(this.currentUserData) === true && Array.isArray(this.invoiceTemplates);
        }
    },

    async created() {
        this.loading = true;
        await getInvoicetemplates()
            .then((templates) => {
                this.invoiceTemplates = templates;
            })
            .finally(() => {
                this.loading = false;
            });
    },

    methods: {
        async changeInvoiceLayout(value) {
            this.loading = true;

            try {
                notify.call(this, {
                    title: this.$t('settings.succesfuly_updated'),
                    text: this.$t('settings.succesfuly_updated')
                });

                await setInvoicelayoutRequest(value);
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },
        async renderHTML(invoiceTemplate) {
            this.invoiceHTML = await previewInvoiceLayoutRequest(invoiceTemplate);
            this.showBottomSheet = true;
        }
    }
};
</script>

<style lang="scss">
.invoiceTemplate-wrapper {
    display: flex;
    justify-content: space-evenly;

    .invoiceTemplate {
        border: 2px solid $color-border;
    }
}
</style>
