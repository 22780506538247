<template>
    <v-form v-model="isValid">
        <div class="social-secretariat">
            <v-form v-model="isValid">
                <v-select
                    v-model="selectedSocialSecretariat"
                    hide-details="auto"
                    :items="socialSecretariats"
                    item-text="name"
                    item-value="id"
                    :label="$t('general.social_secretariat')"
                    outlined
                    dense
                    required
                    :rules="requiredRules"
                />
            </v-form>
        </div>

        <submit-button class="mt-3" :loading="loadingSave" :disabled="!isValid" @click="handleSubmit">
            {{ $t('general.save') }}
        </submit-button>
    </v-form>
</template>

<script>
import { reportError } from '@/helpers/logging';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { sendGetAllSocialsecretariatsRequest } from '@/services/socialsecretariat';
import { capitalize } from 'lodash';
import { mapActions, mapState } from 'vuex';
import { apiErrorAndDisplay } from '../../helpers/errorHandling';
import { sendSetSocialSecretariatPercentageRequest } from '../../services/auth';

export default {
    components: {},
    mixins: [formRulesMixin],
    data() {
        return {
            isValid: false,
            loadingSave: false,
            selectedSocialSecretariat: null,
            socialSecretariats: []
        };
    },
    computed: {
        ...mapState('auth', ['currentUserData'])
    },
    async created() {
        try {
            this.selectedSocialSecretariat = this.currentUserData.socialsecretariat.id;

            this.loading = true;

            this.socialSecretariats = (await sendGetAllSocialsecretariatsRequest()).map((socialsecretariat) => {
                socialsecretariat.name = capitalize(socialsecretariat.name);

                return socialsecretariat;
            });

            this.socialSecretariats.sort((a, b) => (a.name > b.name ? 1 : -1));
        } catch (e) {
            reportError(e);
            this.socialSecretariats = [];
        } finally {
            this.loading = false;
        }
    },
    methods: {
        ...mapActions('socialsecretariats', ['getAllSocialsecretariats']),
        async handleSubmit() {
            try {
                this.loadingSave = true;
                await sendSetSocialSecretariatPercentageRequest({
                    socialsecretariatId: this.selectedSocialSecretariat
                });

                this.$notify({
                    title: this.$t('settings.succesfuly_updated'),
                    text: this.$t('settings.succesfuly_updated'),
                    type: 'success'
                });

                this.currentUserData.socialsecretariat.id = this.selectedSocialSecretariat;
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loadingSave = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/sass/variables.scss';

.statuten-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $space-16;

    &.dense {
        grid-template-columns: repeat(4, 1fr);
    }
}
</style>
