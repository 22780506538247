<template>
    <div>
        <view-layout>
            <template #header>
                <page-header>
                    <template #title>
                        {{ $t($route.meta.title) }}
                    </template>
                </page-header>
            </template>
            <template #content>
                <section v-if="!loading">
                    <v-row>
                        <v-col cols="12" md="12">
                            <v-card outlined class="mb-4 pa-8">
                                <div v-for="(templateType, type) of templates" id="" :key="type">
                                    <h2>{{ $t(`settings.templates.${getTemplateType(type)}.title`) }}</h2>

                                    <div
                                        v-for="template of templateType"
                                        :id="`${getTemplateType(type)}-${template.type.type}`"
                                        :key="`invoice-${template.id}`"
                                    >
                                        <h3 class="my-4">
                                            {{ getTemplateTitle(template.type.type) }}
                                        </h3>
                                        <edit-email-template
                                            :template="template.template"
                                            @update-template="changeTemplate"
                                        />
                                    </div>

                                    <v-divider class="mb-4" />
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </section>
            </template>
        </view-layout>
        <loader v-if="loading || updateTemplateLoading" />
    </div>
</template>

<script>
// --- Components ---
import Loader from '@/components/Loader.vue';
// --- State ---

// --- Error handling ---
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader.vue';
import { billingMixin } from '../../mixins/billingMixin';
import { notify } from '@/helpers/successNotification';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { getTemplates, updateTemplate } from '@/services/email';
import EditEmailTemplate from '@/components/EditEmailTemplate.vue';

export default {
    components: {
        Loader,
        ViewLayout,
        PageHeader,
        EditEmailTemplate
    },
    mixins: [billingMixin],

    data() {
        return {
            updateTemplateLoading: false,
            loading: false,
            templates: null
        };
    },

    computed: {},

    async created() {
        try {
            this.loading = true;

            this.templates = await getTemplates();
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
        } finally {
            this.loading = false;
        }
    },
    methods: {
        invoiceTemplates(templates) {
            if (!templates) {
                return [];
            }

            return templates.invoiceTemplates;
        },
        quotationTemplates(templates) {
            if (!templates) {
                return [];
            }

            return templates.quotationTemplates;
        },
        creditnoteTemplates(templates) {
            if (!templates) {
                return [];
            }

            return templates.creditnoteTemplates;
        },
        getTemplateTitle(type) {
            return this.$t(`templateTypes.${type}`);
        },
        async changeTemplate(template) {
            try {
                this.updateTemplateLoading = true;

                await updateTemplate(template.id, template);

                notify.call(this, {
                    title: this.$t('calendarevent.succesfuly_saved'),
                    text: this.$t('calendarevent.succesfuly_saved'),
                    type: 'success'
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.updateTemplateLoading = false;
            }
        },
        getTemplateType(type) {
            if (type === 'creditnoteTemplates') {
                return 'creditnote';
            } else if (type === 'invoiceTemplates') {
                return 'invoice';
            } else if (type === 'quotationTemplates') {
                return 'quotation';
            }

            return '';
        }
    }
};
</script>
<style lang="scss" scoped>
ul.listPlaceholders {
    padding-left: 0px;

    li {
        list-style-type: none !important;
    }
}
</style>
