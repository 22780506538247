<template>
    <div id="subscriptionPage">
        <view-layout>
            <template #header>
                <page-header>
                    <template #title> {{ $t($route.meta.title) }}</template>
                </page-header>
            </template>
            <template v-if="stripeLoaded && stripeFailedToLoad === false" #content>
                <div v-if="subscriptionInfo">
                    <component
                        :is="subscriptionBarComponent"
                        :subscription-info="subscriptionInfo"
                        @click-undo-cancel-subscription="handleUndoCancelSubscription"
                        @click-cancel-subscription="openCancelSubscriptionDialog"
                    />

                    <select-subscription
                        v-if="canSelectSubscription"
                        :plans="eligablePlansForSubscription"
                        :current-payment-method="userBillinginfo.paymentMethod"
                        :promocodes="subscriptionInfo.promocodes"
                        @choose-subscription="handleUserChoseSubscription"
                    />

                    <div v-if="hasInvoices" class="py-3">
                        <v-card outlined class="mb-4 pa-8">
                            <h2>{{ $t('general.invoices') }}</h2>
                            <invoice-list :invoices="invoiceList" />
                        </v-card>
                    </div>
                </div>
            </template>
            <template v-else #content>
                <div>{{ $t('subscription.could_not_connect_to_subscription_service') }}</div>
            </template>
        </view-layout>

        <confirmation-dialog
            v-if="cancelSubscriptionDialog"
            v-model="cancelSubscriptionDialog"
            :title="$t('settings.cancel_subscription')"
            :message="$t('settings.cancel_subscription_text')"
            :loading="loading"
            :negative-button="$t('general.cancel')"
            :positive-button="$t('general.yes')"
            :positive-color="'red'"
            @click-positive="handleCancelSubscriptionDialog"
            @click-negative="cancelSubscriptionDialog = false"
            @click-outside="cancelSubscriptionDialog = false"
        />

        <confirmation-dialog
            v-if="moveToMainDialog"
            v-model="moveToMainDialog"
            :title="$t('settings.move_to_main_subscription')"
            :message="$t('settings.move_to_main_subscription_text')"
            :loading="loading"
            :negative-button="$t('general.cancel')"
            :positive-button="$t('general.yes')"
            :positive-color="'red'"
            @click-positive="handleSwitchToMain"
            @click-negative="moveToMainDialog = false"
            @click-outside="moveToMainDialog = false"
        />

        <loader v-if="loading" />
    </div>
</template>

<script>
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
import Loader from '@/components/Loader';
import PageHeader from '@/components/PageHeader.vue';
import SubmitButton from '@/components/SubmitButton.vue';
import ViewLayout from '@/components/ViewLayout.vue';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { reportError } from '@/helpers/logging';
import { notify } from '@/helpers/successNotification';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import InvoiceList from '@/modules/subscription/presentation/components/InvoiceList.vue';
import OnetimeSubscriptionBar from '@/modules/subscription/presentation/components/OnetimeSubscriptionBar.vue';
import RenewableSubscriptionBar from '@/modules/subscription/presentation/components/RenewableSubscriptionBar.vue';
import { analyticsTrack } from '@/services/analytics';
import { dayjs } from '@/setup/dayjs';
import { mapActions, mapState } from 'vuex';
import { sendGetInvoicesRequest } from '../../../services/auth';
import productService from '../domain/product.service';
import SelectSubscription from './components/SelectSubscription.vue';
import SubscriptionFrequencySelect from './components/SubscriptionFrequencySelect.vue';
import SubscriptionStepFrequency from './components/SubscriptionStepFrequency.vue';
import SubscriptionStepSelectPaymentMethod from './components/SubscriptionStepSelectPaymentMethod.vue';
import SubscriptionStepSelectVersion from './components/SubscriptionStepSelectVersion.vue';
import { getCombinedInvoiceList } from './helpers';

export default {
    components: {
        Loader,
        SelectSubscription,
        ConfirmationDialog,
        ViewLayout,
        PageHeader,
        InvoiceList,
        RenewableSubscriptionBar,
        OnetimeSubscriptionBar,
        SubmitButton,
        SubscriptionFrequencySelect,
        SubscriptionStepSelectPaymentMethod,
        SubscriptionStepFrequency,
        SubscriptionStepSelectVersion
    },

    mixins: [formRulesMixin],

    data() {
        return {
            selectedPriceID: null,
            loading: false,
            dialog: false,
            cancelSubscriptionDialog: false,
            moveToMainDialog: false,
            stepper: 1,
            subscriptionInfo: null,
            paymentMethodApplication: null,
            productsWithPrices: null,
            stripeLoaded: false,
            stripeAPI: null,
            stripeFailedToLoad: false,
            dexxterInvoices: [],
            stripeInvoices: []
        };
    },

    computed: {
        ...mapState('auth', ['userBillinginfo']),
        ...mapState(['serverInfo']),
        invoiceList() {
            return getCombinedInvoiceList({
                dexxterInvoices: this.dexxterInvoices,
                stripeInvoices: this.stripeInvoices
            });
        },
        canShowUpdateToLiteTier() {
            if (!this.userBillinginfo) {
                return false;
            }

            return this.userBillinginfo.subscriptionType === 'pro_tier';
        },
        eligablePlansForSubscription() {
            if (!this.productsWithPrices) {
                return [];
            }

            return productService.eligablePlansForSubscription(
                this.productsWithPrices,
                this.userBillinginfo.hasTrialSubscription(),
                this.userBillinginfo.hasFreeSubscription(),
                this.userBillinginfo.hasTrialSubscription() ? 'MONTHLY' : this.userBillinginfo.interval,
                this.userBillinginfo.paymentMethod
            );
        },
        subscriptionBarComponent() {
            if (this.hasRenewableSubscription) {
                return RenewableSubscriptionBar;
            } else if (this.userBillinginfo.hasOnetimeSubscription()) {
                return OnetimeSubscriptionBar;
            }

            return null;
        },
        hasInvoices() {
            return this.invoiceList.length > 0;
        },
        canSelectSubscription() {
            return this.eligablePlansForSubscription.length > 0;
        },
        hasRenewableSubscription() {
            return this.userBillinginfo.hasRenewableSubscription();
        }
    },

    beforeMount() {
        const stripeScript = document.createElement('script');
        stripeScript.setAttribute('src', 'https://js.stripe.com/v3');
        stripeScript.addEventListener('load', () => {
            this.stripeLoaded = true;
            this.stripeAPI = window.Stripe(this.serverInfo.stripePublishableKey);
        });
        stripeScript.addEventListener('error', (err) => {
            this.stripeFailedToLoad = true;
            reportError(err);
        });
        document.head.appendChild(stripeScript);
    },

    created() {
        this.loading = true;
        Promise.all([this.fetchData(), this.fetchProducts()]).finally(() => {
            this.loading = false;
        });
    },

    methods: {
        ...mapActions('auth', ['getCurrentUserBillinginfo']),
        async handleSwitchToMain() {
            try {
                if (this.userBillinginfo.hasFreeSubscription()) {
                    throw new Error('User must have susbcription');
                }

                await this.handleUpdateSubscription();

                window.location = '/user-settings/subscription/success';
                return;
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            }
        },
        openMoveToMainConfirmation() {
            this.moveToMainDialog = true;
        },
        moveToNextStep() {
            this.stepper = parseInt(this.stepper) + 1;
        },
        openCancelSubscriptionDialog() {
            this.cancelSubscriptionDialog = true;
        },
        async fetchProducts() {
            this.productsWithPrices = await productService.getProducts();
        },
        handleBancontactPayment(planID) {
            this.loading = true;
            productService
                .createCheckoutSession(planID)
                .then((data) => {
                    // Call Stripe.js method to redirect to the new Checkout page
                    return this.stripeAPI.redirectToCheckout({
                        sessionId: data.sessionId
                    });
                })
                .catch((error) => {
                    apiErrorAndDisplay.call(this, error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        handleCardPayment(planID) {
            this.loading = true;
            productService
                .createCheckoutSessionCard(planID)
                .then((data) => {
                    // Call Stripe.js method to redirect to the new Checkout page
                    return this.stripeAPI.redirectToCheckout({
                        sessionId: data.sessionId
                    });
                })
                .catch((error) => {
                    apiErrorAndDisplay.call(this, error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        handleCustomerPortal() {
            this.loading = true;
            productService
                .createPortalSession()
                .then((data) => {
                    window.location.href = data.url;
                })
                .catch((error) => {
                    apiErrorAndDisplay.call(this, error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        fetchData() {
            return this.fetchUserInformation();
        },
        handleUserChoseSubscription({ selectedTier, subscriptionInterval, paymentMethodApplication }) {
            this.selectedPriceID = productService.getPriceID(
                this.productsWithPrices,
                selectedTier,
                subscriptionInterval,
                paymentMethodApplication
            );

            this.paymentMethodApplication = paymentMethodApplication;

            this.startSelectedPaymentMethod();
        },
        async handleCancelSubscriptionDialog() {
            this.loading = true;
            try {
                await productService.cancelSubscription();

                try {
                    analyticsTrack('User Cancels Subscription', {
                        Enterprise_Plan_Interval: this.subscriptionInfo.billinginfo.interval,
                        Enterprise_Plan_Type: this.subscriptionInfo.billinginfo.subscriptionType
                    });
                } catch (e) {
                    reportError(e);
                }

                // Request succeeded
                notify.call(this, {
                    title: this.$t('settings.subscription_succesfuly_canceled'),
                    text: this.$t('settings.subscription_succesfuly_canceled'),
                    type: 'success'
                });

                await this.fetchData();

                this.cancelSubscriptionDialog = false;
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },
        handleUpdateSubscription() {
            this.loading = true;
            return productService
                .updateSubscriptionToMainPlan()
                .catch((error) => {
                    apiErrorAndDisplay.call(this, error);
                    throw error;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async startSelectedPaymentMethod() {
            try {
                if (this.userBillinginfo.hasFreeSubscription() || this.userBillinginfo.isInTrial) {
                    if (this.paymentMethodApplication === 'autorenewal') {
                        this.handleCardPayment(this.selectedPriceID);
                    } else if (this.paymentMethodApplication === 'manual') {
                        this.handleBancontactPayment(this.selectedPriceID);
                    } else {
                        throw new Error('Paymentmethod does not exists');
                    }
                } else {
                    throw new Error('User must have free subscription');
                }
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            }
        },
        async handleUndoCancelSubscription() {
            this.loading = true;
            try {
                await productService.undoCancelSubscription();

                // Request succeeded
                notify.call(this, {
                    title: this.$t('settings.subscription_succesfuly_uncanceled'),
                    text: this.$t('settings.subscription_succesfuly_uncanceled'),
                    type: 'success'
                });

                await this.fetchData();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },
        async fetchUserInformation() {
            this.subscriptionInfo = await this.getCurrentUserBillinginfo();

            const invoices = await sendGetInvoicesRequest();

            this.dexxterInvoices = invoices.dexxter.map((_x) => ({
                date: _x.date,
                amountEuroInclVat: _x.amountEuroInclVAT,
                url: _x.url,
                id: _x.id
            }));

            this.stripeInvoices = invoices.stripe.map((_x) => ({
                date: dayjs.unix(_x.created).format('YYYY-MM-DD'),
                amountEuroInclVat: _x.total / 100,
                url: _x.invoice_pdf,
                id: _x.id
            }));
        },
        submit() {
            // You will be redirected to Stripe's secure checkout page
            this.$refs.checkoutRef.redirectToCheckout();
        }
    }
};
</script>
<style lang="scss" scoped>
#subscriptionPage {
    & :deep() .SubscriptionPlanWrapper {
        text-align: center;
        padding: 40px;
        border: 1px solid $grey-100;
        border-radius: $border-radius-root;
        position: relative;
        padding-top: 20px + 30px;

        .SubscriptionTitle {
            font-size: 1.5em;
        }
        .SubscriptionPrice {
            font-size: 1.2em;
            color: $dexxter-web-blue;
        }
        .SubscriptionPriceInfo {
            color: $grey-500;
        }

        .SubscriptionButton {
            margin-top: 15px;
        }

        .PopularBar {
            border-top-left-radius: $border-radius-root;
            border-top-right-radius: $border-radius-root;
            position: absolute;
            color: white;
            background-color: $dexxter-web-blue;
            width: 100%;
            top: 0px;
            left: 0px;
            font-size: $font-size-root;
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }
}
</style>
