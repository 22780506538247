<template>
    <v-card class="pa-5">
        <v-row cols="12" md="12" class="pt-0 mt-0">
            <v-col cols="12" class="pt-0 mt-0">
                <table class="emailCard-table">
                    <tr>
                        <td>Aan</td>
                        <td>{{ email.to }}</td>
                    </tr>
                    <tr>
                        <td>CC</td>
                        <td>{{ cc }}</td>
                    </tr>
                    <tr>
                        <td>BCC</td>
                        <td>{{ bcc }}</td>
                    </tr>
                    <tr>
                        <td style="vertical-align: top">Tekst</td>
                        <td v-html="$options.filters.santizeHTML(email.text)"></td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            <v-simple-table dense class="history">
                                <template #default>
                                    <thead>
                                        <tr>
                                            <th class="text-left">Status</th>
                                            <th class="text-left">E-mail</th>
                                            <th class="text-left">Ontvangen op</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="status in email.status" :key="status.id">
                                        <tr>
                                            <td>
                                                {{ getStatusText(status.status) }}
                                            </td>
                                            <td>
                                                {{ status.recipient }}
                                            </td>
                                            <td>{{ convertTimeStampToDate(status.createdAt) }}</td>
                                        </tr>
                                        <template v-if="status.status === 'BOUNCED'">
                                            <tr>
                                                <td colspan="3" class="py-2">
                                                    <v-icon color="red">mdi-alert</v-icon
                                                    ><span class="font-weight-thin">{{ status.errorMessage }}</span>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </td>
                    </tr>
                </table>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import dayjs from 'dayjs';
export default {
    inheritAttrs: false,

    props: {
        email: {
            type: Object,
            required: true
        }
    },

    computed: {
        cc() {
            return this.email.cc ?? '/';
        },
        bcc() {
            return this.email.bcc ?? '/';
        }
    },

    methods: {
        convertTimeStampToDate(date) {
            return dayjs(date).format('DD-MM-YYYY HH:mm');
        },
        getStatusText(status) {
            return this.$t(`emailStatus.${status}`);
        }
    }
};
</script>

<style lang="scss">
table.emailCard-table {
    border-collapse: collapse;

    table {
        width: 100% !important;
        table-layout: fixed !important;
    }

    tr {
        border: solid;
        border-width: 1px 0;
        border-color: $grey-100;
    }

    tr:first-child {
        border-top: none;
    }
    tr:last-child {
        border-bottom: none;
    }

    td:nth-child(1) {
        padding: 10px;
        font-weight: bold;
    }
    td:nth-child(2) {
        padding: 10px;
        width: 100%;
    }
}
</style>
