<template>
    <div>
        <v-form v-if="!loadingComponent">
            <v-tabs v-model="languageTab">
                <v-tab
                    v-for="termsAndCondition in currentUserData.settings.termsandconditions"
                    :key="termsAndCondition.languageId"
                >
                    {{ getLanguageName(termsAndCondition.languageId) }}
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="languageTab">
                <v-tab-item
                    v-for="termsAndCondition in currentUserData.settings.termsandconditions"
                    :key="termsAndCondition.languageId"
                >
                    <editor
                        v-model="termsAndCondition.text"
                        type="card"
                        name="termsandconditions"
                        :placeholder="$t('settings.terms_and_conditions')"
                        :rules="requiredRules"
                    >
                    </editor>
                </v-tab-item>
            </v-tabs-items>
            <v-card-actions>
                <v-spacer />
                <submit-button color="primary" :loading="loadingSubmit" @click="changeTermsAndConditions">
                    {{ $t('general.save') }}
                </submit-button>
            </v-card-actions>
        </v-form>
        <loader v-else />
    </div>
</template>

<script>
// --- States ---
import { mapState } from 'vuex';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import Editor from '@/components/Editor/DefaultEditor.vue';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { sendSetTermsAndConditionsRequest } from '@/services/usersettings';
import { sendGetAllInvoicableLanguagesRequest } from '@/services/invoicableLanguages';

import { i18nCodeToLocali18nName } from '@/helpers/language';

export default {
    components: {
        Editor
    },

    mixins: [formRulesMixin],

    data() {
        return {
            loadingComponent: false,
            loadingSubmit: false,
            languageTab: null,
            languages: []
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData'])
    },

    async created() {
        try {
            this.loadingComponent = true;
            this.languages = await sendGetAllInvoicableLanguagesRequest();
        } catch (e) {
            apiErrorAndDisplay(this, e);
        } finally {
            this.loadingComponent = false;
        }
    },

    methods: {
        async changeTermsAndConditions() {
            this.loadingSubmit = true;
            try {
                await sendSetTermsAndConditionsRequest(this.currentUserData.settings.termsandconditions);

                notify.call(this, {
                    title: this.$t('settings.succesfuly_updated'),
                    text: this.$t('settings.succesfuly_updated')
                });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loadingSubmit = false;
            }
        },
        getLanguageName(id) {
            const language = this.languages.find((language) => language.id === id);

            if (!language) {
                throw new Error('Language not found');
            }

            return i18nCodeToLocali18nName(language.code);
        }
    }
};
</script>
