<template>
    <div v-if="editor">
        <editor-menu class="editor__header" :editor="editor" :custom-menu-items="customMenuItems" />
        <editor-content class="editor" :editor="editor" />
    </div>
</template>

<script>
import { editorMixin } from './editor.mixin';

import { invoicablePlacholders } from '@/views/settings/templates/placeholders';

export default {
    mixins: [editorMixin],

    data() {
        return {
            customMenuItems: [
                {
                    type: 'group',
                    name: this.$t('settings.templates.placeholders'),
                    items: invoicablePlacholders.map((placeholder) => {
                        return {
                            title: this.$t(`placeholders.${placeholder}`),
                            action: () => this.editor.chain().insertContent(placeholder).run()
                        };
                    })
                }
            ]
        };
    }
};
</script>
