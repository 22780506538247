import { groupBy } from 'lodash';
import productRepository from '../infrastructure/product.repository';
import {
    intervalTypeApplication,
    intervalTypeRecurringStripe,
    paymentMethod,
    paymentMethodApplication,
    paymentTypeStripe,
    productCategory,
    subscriptionTypes
} from './interfaces';
import { mapPlanPriceModelToDomain } from './mappers/planPriceModel.map';
import { PlanPriceInfo } from './models/PlanPriceInfo';
import { ProductRepositoryInterface } from './product.repository.interface';

class SubscriptionService {
    subscriptionRepository: ProductRepositoryInterface;

    constructor() {
        this.subscriptionRepository = productRepository;
    }

    getAllAvailablePaymentMethods(): paymentMethod[] {
        return [
            {
                label: 'Bancontact/iDEAL  (manueel je abonnement verlengen)',
                value: 'manual'
            },
            {
                label: 'Creditcard',
                value: 'autorenewal'
            }
        ];
    }

    getPlansForCurrentPaymentMethod(
        pricePlanInfo: PlanPriceInfo[],
        currentPaymentMethod: paymentMethodApplication
    ): PlanPriceInfo[] {
        if (!currentPaymentMethod) {
            return this.excludeFreeTierPlan(pricePlanInfo);
        }

        //at this point we only allow subscription based to upgrade
        if (currentPaymentMethod === 'MANUAL') {
            return [];
        }

        return pricePlanInfo.filter((plan) => {
            return this.canUpgradeWithCurrentPaymentMethod(plan, currentPaymentMethod);
        });
    }

    private canUpgradeWithCurrentPaymentMethod(
        pricePlanInfo: PlanPriceInfo,
        currentPaymentMethod: paymentMethodApplication
    ): boolean {
        if (currentPaymentMethod === 'AUTORENEWAL' && pricePlanInfo.price.metadata.paymentType === 'autorenewal') {
            return true;
        } else if (currentPaymentMethod === 'MANUAL' && pricePlanInfo.price.metadata.paymentType === 'manual') {
            return true;
        }

        return false;
    }

    getPlansForCurrentInterval(
        pricePlanInfo: PlanPriceInfo[],
        currentInterval: intervalTypeApplication
    ): PlanPriceInfo[] {
        if (!currentInterval) {
            return this.excludeFreeTierPlan(pricePlanInfo);
        }

        return pricePlanInfo.filter((pricePlanInfo: PlanPriceInfo) => {
            return this.canUpgradeWithCurrentInterval(pricePlanInfo, currentInterval);
        });
    }

    private canUpgradeWithCurrentInterval(
        pricePlanInfo: PlanPriceInfo,
        currentInterval: intervalTypeApplication
    ): boolean {
        if (pricePlanInfo.plan.isManualPlan()) {
            return true;
        }

        return this.getValidIntervalsForCurrentPricingInterval(currentInterval).includes(pricePlanInfo.interval);
    }

    getValidIntervalsForCurrentPricingInterval(interval: intervalTypeApplication): ('month' | 'year')[] {
        if (interval === 'MONTHLY') {
            return ['month', 'year'];
        } else if (interval === 'YEARLY') {
            return ['year'];
        }

        throw new Error(`Invalid interval ${interval}`);
    }

    private excludeFreeTierPlan(pricePlanInfo: PlanPriceInfo[]) {
        return pricePlanInfo.filter((priceplan) => {
            return !priceplan.isFreeTier();
        });
    }

    eligablePlansForSubscription(
        productsWithPlans: PlanPriceInfo[],
        isInTrial: boolean,
        isFree: boolean,
        currentInterval: intervalTypeApplication,
        currentPaymentMethod: paymentMethodApplication
    ): PlanPriceInfo[] {
        let result = this.excludeFreeTierPlan(productsWithPlans);

        if (isInTrial || isFree) {
            return result;
        }

        // TODO: Rework to pipe operator
        result = this.getPlansForCurrentInterval(result, currentInterval);
        result = this.getPlansForCurrentPaymentMethod(result, currentPaymentMethod);

        return result;
    }

    eligablePricingsForSelectedTier(plans: PlanPriceInfo[], selectedTier: productCategory): PlanPriceInfo[] {
        if (!selectedTier) {
            return [];
        }

        const result = plans.filter((plan) => {
            return plan.subscriptionCategory === selectedTier;
        });

        const hasYearlyPlan = plans.some((plan) => {
            return plan.isRecurring() && plan.isYearly();
        });

        const hasManualPlan = plans.some((plan) => {
            return plan.isManualPlan();
        });

        if (hasYearlyPlan && hasManualPlan) {
            // Filter the manual plan out of it
            return result.filter((plan) => {
                return plan.isRecurring();
            });
        }

        return result;
    }

    productTiers(plans: PlanPriceInfo[]): subscriptionTypes[] {
        if (!plans || (Array.isArray(plans) && plans.length === 0)) {
            return [];
        }

        return Object.keys(
            groupBy(plans, (plan) => {
                return plan.subscriptionCategory;
            })
        ) as subscriptionTypes[];
    }

    getPriceID(
        remainingPlaninfo: PlanPriceInfo[],
        selectedSubscriptionTier: productCategory,
        interval: intervalTypeRecurringStripe,
        paymentMethodApplication: paymentTypeStripe
    ) {
        const products = remainingPlaninfo.filter((_planinfo) => {
            if (paymentMethodApplication === 'autorenewal') {
                return (
                    _planinfo.isRecurring() &&
                    _planinfo.isIntervalPlan(interval) &&
                    _planinfo.isCategoryTier(selectedSubscriptionTier)
                );
            } else if (paymentMethodApplication === 'manual') {
                return _planinfo.isManualPlan() && _planinfo.isCategoryTier(selectedSubscriptionTier);
            }

            throw new Error('Invalid plan');
        });

        if (products.length === 0) {
            throw new Error('Price not found');
        }

        if (products.length > 1) {
            throw new Error('Multiple products found');
        }

        return products[0].plan.id;
    }

    availablePaymentMethods(
        subscriptionInterval: intervalTypeRecurringStripe,
        paymentMethods: paymentMethod[],
        currentPaymentMethod: paymentMethodApplication
    ): paymentMethod[] {
        if (!currentPaymentMethod) {
            if (!subscriptionInterval) {
                return [];
            }

            if (subscriptionInterval === 'year') {
                return paymentMethods;
            }

            return paymentMethods.filter((_paymentmethod) => {
                return _paymentmethod.value === 'autorenewal';
            });
        }

        if (currentPaymentMethod === 'AUTORENEWAL') {
            return paymentMethods.filter((_paymentmethod) => {
                return _paymentmethod.value === 'autorenewal';
            });
        } else {
            return paymentMethods.filter((_paymentmethod) => {
                return _paymentmethod.value !== 'autorenewal';
            });
        }
    }

    getProducts(): Promise<PlanPriceInfo[]> {
        return this.subscriptionRepository.getProducts().then((products) => {
            return mapPlanPriceModelToDomain(products);
        });
    }
    cancelSubscription() {
        return this.subscriptionRepository.cancelSubscription();
    }
    undoCancelSubscription() {
        return this.subscriptionRepository.undoCancelSubscription();
    }
    createCheckoutSession(planID: string) {
        return this.subscriptionRepository.createCheckoutSession(planID);
    }
    updateSubscriptionToMainPlan() {
        return this.subscriptionRepository.updateSubscriptionToMainPlan();
    }
    createCheckoutSessionCard(planID: string) {
        return this.subscriptionRepository.createCheckoutSessionCard(planID);
    }
    createPortalSession() {
        return this.subscriptionRepository.createPortalSession();
    }
}

export default new SubscriptionService();
