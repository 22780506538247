<template>
    <v-form v-if="userSettings" v-model="valid">
        <CounterNumberField
            v-model="userSettings.invoiceCounter"
            name="invoiceCounter"
            :i18n-label="'settings.counters.invoice.label'"
            :counter-preview-text="counterPreview('invoice')"
        />
        <CounterNumberField
            v-model="userSettings.quotationCounter"
            name="quotationCounter"
            :i18n-label="'settings.counters.quotation.label'"
            :counter-preview-text="counterPreview('quotation')"
        />
        <CounterNumberField
            v-model="userSettings.creditnoteCounter"
            name="creditnoteCounter"
            :i18n-label="'settings.counters.creditnote.label'"
            :counter-preview-text="counterPreview('creditnote')"
        />

        <v-card-actions>
            <v-spacer />
            <submit-button data-id="submit-counters" :disabled="!valid" color="primary" @click="submitCounters">
                {{ $t('general.save') }}
            </submit-button>
        </v-card-actions>
    </v-form>
</template>

<script>
// --- States ---
import { mapActions, mapState } from 'vuex';
// --- Components ---
import CounterNumberField from '@/components/FormComponents/CounterNumberField';
// --- Mixins  ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
// --- Helpers  ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';

export default {
    components: {
        CounterNumberField
    },
    mixins: [formRulesMixin],
    data() {
        return {
            userSettings: null,
            valid: false,
            loading: false,
            isPreviewVisible: {
                invoice: false,
                quotation: false,
                creditnote: false
            }
        };
    },
    computed: {
        ...mapState(['selectedYear'])
    },

    async created() {
        this.userSettings = await this.getTaxSettings({ year: this.selectedYear });
    },
    methods: {
        ...mapActions('auth', ['setIncomeCounters']),
        ...mapActions('taxes', ['getTaxSettings']),
        counterPreview(counterKey) {
            if (this.userSettings) {
                return `${this.$i18n.t(`settings.counters.${counterKey}.previewText`)} ${this.selectedYear}-${
                    this.userSettings[`${counterKey}Counter`]
                }`;
            }
            return '';
        },
        async submitCounters() {
            try {
                await this.setIncomeCounters({
                    invoiceCounter: this.userSettings.invoiceCounter,
                    quotationCounter: this.userSettings.quotationCounter,
                    creditnoteCounter: this.userSettings.creditnoteCounter
                });

                this.$notify({
                    title: this.$t('settings.succesfuly_updated'),
                    text: this.$t('settings.succesfuly_updated'),
                    type: 'success'
                });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            }
        }
    }
};
</script>

<style scoped lang="scss"></style>
