<template>
    <div class="SubscriptionPlanWrapper">
        <div class="SubscriptionTitle">
            {{ $t(`subscription.products.${productTier}`) }}
        </div>

        <submit-button :data-action="productTier" class="SubscriptionButton" @click="$emit('choose', productTier)">{{
            $t('subscription.choose')
        }}</submit-button>
    </div>
</template>

<script>
export default {
    props: ['productTier']
};
</script>
<style scoped>
.SubscriptionPlanWrapper {
    height: 100%;
}
</style>
