<template>
    <div class="horiontal-scroll-mobile">
        <v-row>
            <v-col cols>
                <v-card class="Widget pa-5" outlined>
                    <data-card-multiple
                        :data="endsOnFormat"
                        :data-text="$t('subscription.ends_at')"
                        avatar-color="red lighten-5"
                        icon-color="red lighten-1"
                        :disable-numeric-format="true"
                    />
                </v-card>
            </v-col>
            <v-col cols>
                <v-card class="Widget pa-5" outlined>
                    <data-card-multiple
                        :data="typeOfSubscription"
                        :data-text="$t('subscription.type_of_subscription')"
                        avatar-color="red lighten-5"
                        icon-color="red lighten-1"
                        :disable-numeric-format="true"
                    />
                </v-card>
            </v-col>
            <v-col cols>
                <v-card class="Widget pa-5" outlined>
                    <data-card-multiple
                        :data="$t('subscription.renew_manual')"
                        :data-text="$t('subscription.renew')"
                        avatar-color="red lighten-5"
                        icon-color="red lighten-1"
                        :disable-numeric-format="true"
                    />
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { BILLING_PLANS } from '@/config/constants';
import DataCardMultiple from '@/components/DataCardMultiple';
export default {
    components: {
        DataCardMultiple
    },

    props: {
        subscriptionInfo: {}
    },

    computed: {
        endsOnFormat() {
            return this.$options.filters.formatDate(this.subscriptionInfo.billinginfo.currentPeriodEnds);
        },
        typeOfSubscription() {
            const type = this.subscriptionInfo.billinginfo.subscriptionType;

            if (type === BILLING_PLANS.PRO_TIER) {
                return 'Pro';
            } else if (type === BILLING_PLANS.FREE_TIER) {
                return 'Free';
            } else if (type === BILLING_PLANS.LITE_TIER) {
                return 'Lite';
            } else {
                throw new Error('unknown type');
            }
        }
    }
};
</script>
