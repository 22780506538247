import { IProductPlanInformation } from '../product.repository.interface';
import { PlanPriceInfo } from '../models/PlanPriceInfo';
import { Plan } from '../models/Plan';
import { Price } from '../models/Price';

export function mapPlanPriceModelToDomain(data: IProductPlanInformation[]): PlanPriceInfo[] {
    const result: PlanPriceInfo[] = [];

    data.forEach((product) => {
        product.plans.forEach((plan) => {
            result.push(
                new PlanPriceInfo(
                    new Plan(plan.id, plan.type, plan.unit_amount, plan.recurring),
                    new Price(product.id, product.metadata)
                )
            );
        });
    });

    return result;
}
