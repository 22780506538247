<template>
    <div class="SubscriptionPlanWrapper">
        <div class="SubscriptionTitle">{{ $t(`subscription.frequencies.${interval}`) }}</div>
        <div class="SubscriptionPrice">€{{ pricing.plan.unit_amount / 100 }}</div>
        <div class="SubscriptionPriceInfo">Per {{ $t(`general.${interval}`) }}</div>

        <submit-button :data-action="interval" class="SubscriptionButton" @click="$emit('choose', interval)">{{
            $t('subscription.choose')
        }}</submit-button>
    </div>
</template>

<script>
export default {
    props: ['pricing'],

    computed: {
        interval() {
            if (!this.pricing.plan.recurring) {
                return 'year';
            }

            return this.pricing.plan.recurring.interval;
        }
    }
};
</script>
