<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
            </page-header>
        </template>
        <template #content>
            <v-card outlined class="mb-4 pa-8">
                <div v-if="!userHasAccountantEmailBCCs">
                    <h2 class="my-4">
                        {{ $t('settings.add') }}
                    </h2>
                    <AccountantEmailBCCCreate :loading="loading" @submit="createAccountantEmailBCC" />
                </div>

                <div v-else>
                    <div v-for="accountantemailbcc in accountantEmailBCCs" :key="accountantemailbcc.email">
                        <h2>
                            {{ $t('settings.your_accountant_is') }}:
                            <span class="font-weight-regular">{{
                                accountantemailbcc.name + ' / ' + accountantemailbcc.email
                            }}</span>
                        </h2>

                        <v-card-actions>
                            <v-spacer />
                            <v-btn color="error" text @click="deleteAccountantEmailBCC(accountantemailbcc.id)">
                                {{ $t('settings.remove_accountant') }}
                            </v-btn>
                        </v-card-actions>
                    </div>
                </div>
            </v-card>

            <loader v-if="loading" />
        </template>
    </view-layout>
</template>

<script>
import Loader from '@/components/Loader';
// --- State ---
import { mapActions } from 'vuex';
// --- Components ---
import AccountantEmailBCCCreate from '@/components/AccountantEmailBCCCreate.vue';

// --- Error handling ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader.vue';
import { notify } from '@/helpers/successNotification';

import * as AccountantemailbccService from '@/services/accountantemailbcc';

export default {
    components: {
        AccountantEmailBCCCreate,
        Loader,
        ViewLayout,
        PageHeader
    },

    data() {
        return {
            loading: false,
            accountantEmailBCCs: []
        };
    },

    computed: {
        userHasAccountantEmailBCCs() {
            return this.accountantEmailBCCs.length > 0;
        }
    },

    async created() {
        try {
            this.loading = true;
            await this.getAccountantEmailBCC().then((result) => {
                this.accountantEmailBCCs = result;
            });
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
        } finally {
            this.loading = false;
        }
    },

    methods: {
        ...mapActions('accountantEmailBCC', ['getAccountantEmailBCC', 'refetchAccountantEmailBCC']),
        async deleteAccountantEmailBCC(id) {
            this.loading = true;

            try {
                await AccountantemailbccService.deleteAccountantEmailBCC(id);
                this.accountantEmailBCCs = await this.refetchAccountantEmailBCC();

                notify.call(this, {
                    title: this.$t('general.succesfuly_deleted'),
                    text: this.$t('general.succesfuly_deleted'),
                    type: 'success'
                });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        },
        async createAccountantEmailBCC(data) {
            try {
                this.loading = true;

                await AccountantemailbccService.createAccountantEmailBCC(data);
                this.accountantEmailBCCs = await this.refetchAccountantEmailBCC();

                notify.call(this, {
                    title: this.$t('general.succesfuly_created'),
                    text: this.$t('general.succesfuly_created'),
                    type: 'success'
                });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
