<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
            </page-header>
        </template>
        <template #content>
            <TableCard>
                <template #content>
                    <template v-if="hasAccessToFeature(['paymentlinkMollie'], currentUserFeatures)">
                        <tooltip-video-wrapper i18n-path="videotooltips.integrations.mollie" class="mb-3">
                            <template #activator="{ on }">
                                <div class="d-flex align-center justify-center">
                                    <h2 class="mr-1">Mollie</h2>
                                    <v-icon color="primary" @click="on">mdi-youtube</v-icon>
                                </div>
                            </template>
                        </tooltip-video-wrapper>

                        <mollie :integration-id="getIntegrationId('mollie')" @updated-integration="getIntegrations" />
                    </template>
                </template>
            </TableCard>
        </template>
    </view-layout>
</template>

<script>
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';

import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader';
import Mollie from './components/Mollie.vue';
import TableCard from '@/components/TableCard.vue';
import { mapActions, mapState } from 'vuex';
import { hasAccessToFeature } from '@/helpers/feature';
import TooltipVideoWrapper from '@/components/TooltipVideoWrapper.vue';

export default {
    components: { ViewLayout, PageHeader, Mollie, TableCard, TooltipVideoWrapper },
    mixins: [formRulesMixin],

    computed: {
        ...mapState('auth', ['currentUserIntegrations', 'currentUserFeatures'])
    },

    methods: {
        ...mapActions('auth', ['fetchIntegrations']),
        hasAccessToFeature,
        getIntegrations() {
            this.fetchIntegrations();
        },
        getIntegrationId(name) {
            const intgration = this.currentUserIntegrations.find((_integration) => _integration.name === name);
            if (!intgration) {
                return null;
            }

            return intgration.id;
        }
    }
};
</script>

<style lang="scss" scoped></style>
