<template>
    <div class="billed_income-table">
        <v-data-table
            :headers="headers"
            :items="notificationSettings"
            :loading="loading"
            sort-by="date"
            sort-desc
            dense
        >
            <template #[`item.name`]="{ item }">
                {{ $t(`settings.notificationSettingsScreen.${item.notificationtype.name}`) }}
            </template>
            <template #[`item.notification`]="{ item }">
                <v-switch
                    v-model="item.notification"
                    dense
                    @change="updateSmartnotificationSetting('notification', $event, item)"
                ></v-switch>
            </template>

            <template #[`item.email`]="{ item }">
                <v-switch
                    v-model="item.email"
                    dense
                    @change="updateSmartnotificationSetting('email', $event, item)"
                ></v-switch>
            </template>
        </v-data-table>
    </div>
</template>

<script>
// --- State ---
// --- Components ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { getAllNotificationSettings, updateNotificationSettingsRequest } from '@/services/notifications';

export default {
    data() {
        return {
            headers: [
                {
                    text: this.$t('settings.nofication_name'),
                    align: 'start',
                    value: 'name'
                },
                {
                    text: this.$t('settings.via_notification'),
                    align: 'start',
                    value: 'notification'
                },
                { text: this.$t('settings.via_mail'), value: 'email' }
            ],
            loading: false,
            selectedUnit: '',
            notificationSettings: []
        };
    },

    created() {
        this.fetchSmartnotificationSettings();
    },

    methods: {
        fetchSmartnotificationSettings() {
            this.loading = true;
            getAllNotificationSettings()
                .then((notificationSettings) => (this.notificationSettings = notificationSettings))
                .finally(() => (this.loading = false));
        },
        async updateSmartnotificationSetting(type, event, item) {
            this.loading = true;
            try {
                await updateNotificationSettingsRequest(item.id, {
                    [this.getName('notification')]: item.notification,
                    [this.getName('email')]: item.email,

                    // Overwrite value
                    [this.getName(type)]: event
                });

                notify.call(this, {
                    title: this.$t('settings.succesfuly_saved'),
                    text: this.$t('settings.succesfuly_saved')
                });

                this.fetchSmartnotificationSettings();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },
        getName(type) {
            if (type === 'email') {
                return 'email';
            } else if (type === 'notification') {
                return 'notification';
            }

            throw new Error('Invalid type');
        }
    }
};
</script>
