
import { MapStateToComputed } from '@/helpers/types';
import { analyticsTrack } from '@/services/analytics';
import { InitializedUseraccountAuthState } from '@/store/modules/auth';
import Vue from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
    computed: {
        ...(mapState('auth', ['currentUserData']) as MapStateToComputed<InitializedUseraccountAuthState>)
    },

    created() {
        analyticsTrack('Change Plan', {
            Enterprise_Plan_Duration: this.currentUserData.userinformation.billinginfo.interval,
            Enterprise_Plan_Type: this.currentUserData.userinformation.billinginfo.subscriptionType,
            Payment_Method: this.currentUserData.userinformation.billinginfo.paymentMethod
        });

        this.$gtmTrackEvent({
            event: 'purchased-subscription',
            userId: this.currentUserData.id,
            email: this.currentUserData.userinformation.email,
            firstName: this.currentUserData.userinformation.firstName,
            lastName: this.currentUserData.userinformation.name
        });
    }
});
