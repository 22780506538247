<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
            </page-header>
        </template>
        <template #content>
            <section v-if="currentUserData && !loading">
                <v-row>
                    <v-col cols="12">
                        <v-card outlined class="mb-4 pa-8">
                            <template v-if="canSeePreviewInvoice">
                                <h2 class="my-4">
                                    {{ $t('settings.change_invoice_layout') }}
                                </h2>
                                <invoice-template-selector />
                            </template>

                            <template v-if="canShowCountersForm">
                                <v-divider class="my-4" />
                                <base-icon-right i18n-path="tooltips.settings.general.numbers" small class="mb-3">
                                    <h4>{{ $t('settings.numbers') }}</h4>
                                </base-icon-right>
                                <counters-form />
                            </template>

                            <h2 class="my-4">
                                {{ $t('settings.structured_number') }}
                            </h2>
                            <structured-number />

                            <h2 class="my-4">
                                {{ $t('settings.change_email_on_invoicable') }}
                            </h2>
                            <invoicable-email-settings />

                            <h2 class="my-4">
                                <base-icon-right i18n-path="tooltips.settings.invoice.termOfPayment">
                                    {{ $t('settings.change_default_payment_days') }}
                                </base-icon-right>
                            </h2>
                            <default-payment-days-form />

                            <h2 class="my-4">
                                <base-icon-right i18n-path="tooltips.settings.invoice.standardVatAmount">
                                    {{ $t('settings.change_default_vat_percentage') }}
                                </base-icon-right>
                            </h2>
                            <DefaultVATPercentageForm />

                            <v-divider class="my-8" />

                            <base-icon-right i18n-path="tooltips.settings.invoice.general_conditions" class="mb-4">
                                <h2>
                                    {{ $t('settings.terms_and_conditions') }}
                                </h2>
                            </base-icon-right>

                            <terms-and-conditions-form />

                            <h2 class="my-4">
                                {{ $t('settings.units') }}
                            </h2>
                            <div class="d-flex my-4">
                                <v-spacer class="d-none d-sm-flex" />
                                <v-btn small color="primary" @click="newUnit">
                                    <v-icon small left> mdi-plus </v-icon>
                                    {{ $t('units.new_unit') }}
                                </v-btn>
                            </div>
                            <unit-table />
                        </v-card>
                    </v-col>
                </v-row>
            </section>

            <confirmation-dialog
                v-if="dialog"
                v-model="dialog"
                :title="$t('general.register_invite_accountant')"
                :message="$t('general.text_register_invite_accountant')"
                :loading="loading"
                :negative-button="$t('general.cancel')"
                :positive-button="$t('general.confirm_funny')"
                :positive-color="'green'"
                :negative-color="'black'"
                @click-positive="sendRegisterInvitationAccountant"
                @click-negative="dialog = false"
                @click-outside="dialog = false"
            />
            <loader v-if="loading" />
        </template>
    </view-layout>
</template>

<script>
// --- Components ---
import TermsAndConditionsForm from '@/components/Forms/TermsAndConditionsForm.vue';

import DefaultPaymentDaysForm from '@/components/Forms/DefaultPaymentDaysForm.vue';
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';

import Loader from '@/components/Loader.vue';
// --- State ---
import { mapActions, mapState } from 'vuex';

// --- Error handling ---
import UnitTable from '@/components/DataTables/UnitTable.vue';
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader.vue';
import DefaultVATPercentageForm from '@/components/Forms/DefaultVATPercentageForm.vue';
import InvoiceTemplateSelector from './components/InvoiceTemplateSelector.vue';
import { isUserAccount, isUserAccountWithFreeTier } from '@/helpers/auth';
import BaseIconRight from '@/components/BaseIconRight.vue';
import InvoicableEmailSettings from '@/components/Forms/InvoicableEmailSettings.vue';
import StructuredNumber from '@/components/Forms/StructuredNumber.vue';
import CountersForm from '@/components/Forms/CountersForm.vue';

export default {
    components: {
        Loader,
        CountersForm,
        ConfirmationDialog,
        TermsAndConditionsForm,
        UnitTable,
        DefaultPaymentDaysForm,
        ViewLayout,
        PageHeader,
        DefaultVATPercentageForm,
        BaseIconRight,
        InvoicableEmailSettings,
        InvoiceTemplateSelector,
        StructuredNumber
    },

    data() {
        return {
            loading: false,
            dialog: false
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        canShowCountersForm() {
            return isUserAccount(this.currentUserData) && !isUserAccountWithFreeTier(this.currentUserData);
        },
        canSeePreviewInvoice() {
            return isUserAccount(this.currentUserData) === true;
        }
    },

    methods: {
        ...mapActions('bottomSheets', ['newUnit'])
    }
};
</script>

<style lang="scss">
.invoiceTemplate-wrapper {
    display: flex;
    justify-content: space-evenly;

    .invoiceTemplate {
        border: 2px solid $color-border;
    }
}
</style>
