<template>
    <v-form ref="form" v-model="valid">
        <v-row>
            <v-col cols="12">
                <text-field v-model="templateVal.subject" :label="$t('settings.emailSettings.subject')" required />
            </v-col>
            <v-col cols="12">
                <template-editor
                    v-model="templateVal.text"
                    type="card"
                    :placeholder="$t('settings.emailSettings.text_from_email')"
                />
            </v-col>

            <v-col cols="12" md="12">
                <v-card-actions>
                    <v-spacer />
                    <submit-button data-action="save-template" :disabled="!valid" @click="handleSubmit">
                        {{ $t('general.save') }}
                    </submit-button>
                </v-card-actions>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import SubmitButton from './SubmitButton.vue';
import _ from 'lodash';
import TemplateEditor from './Editor/TemplateEditor.vue';

export default {
    components: {
        SubmitButton,
        TemplateEditor
    },

    mixins: [formRulesMixin],

    props: {
        template: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            valid: true,
            templateVal: _.cloneDeep(this.template)
        };
    },

    methods: {
        handleSubmit() {
            this.$emit('update-template', this.templateVal);
        }
    }
};
</script>
