<template>
    <v-simple-table>
        <template #default>
            <thead>
                <tr>
                    <th class="text-left">Datum</th>
                    <th class="text-left">Bedrag incl. BTW</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="invoice in invoices" :key="invoice.id">
                    <td>
                        <a class="link-small" :href="invoice.url" target="_BLANK">{{ invoice.date | formatDate }}</a>
                    </td>
                    <td>€{{ invoice.amountEuroInclVat | numericFormat }}</td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
    props: {
        invoices: {
            required: true
        }
    },

    methods: {
        isInvoicePaid(invoice) {
            return invoice.status === 'paid';
        }
    }
};
</script>
