<template>
    <div class="v-stepper-wrapper">
        <v-stepper-step :editable="canEdit" :complete="stepper > 3" step="3">
            <div class="TooltipTextWrapper">
                {{ $t('subscription.choose_payment_method') }}
            </div>
        </v-stepper-step>
        <v-stepper-content step="3" :class="{ 'stepper-content-active': stepper == 3 }">
            <v-row>
                <v-col cols="12">
                    <div data-select-container="paymentmethod">
                        <v-select
                            v-model="paymentMethodApplication"
                            :label="$t('subscription.choose_payment_method')"
                            outlined
                            dense
                            persistent-hint
                            item-text="label"
                            item-value="value"
                            :items="availablePaymentMethods"
                            required
                            :rules="requiredRules"
                        />
                    </div>
                </v-col>
            </v-row>
        </v-stepper-content>
    </div>
</template>

<script>
// --- Components ---

// --- State ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';

export default {
    mixins: [formRulesMixin],

    props: ['canEdit', 'stepper', 'availablePaymentMethods', 'value'],

    computed: {
        paymentMethodApplication: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            }
        },
        valid() {
            return false;
        }
    }
};
</script>
