<template>
    <v-form v-model="valid">
        <v-row>
            <v-col cols="12">
                <v-checkbox
                    v-model="currentUserData.settings.structuredNumber"
                    :label="$t('settings.invoicableEmailSettings.structuredNumber')"
                    outlined
                    dense
                    hide-details
                    required
                />
            </v-col>
            <v-col cols="12">
                <v-card-actions>
                    <v-spacer />
                    <submit-button color="primary" :disabled="!valid" @click="handleSubmit">
                        {{ $t('general.save') }}
                    </submit-button>
                </v-card-actions>
            </v-col>
        </v-row>
        <loader v-if="loading" />
    </v-form>
</template>

<script>
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { updateStructuredNumberSettingsRequest } from '@/services/usersettings';
import { mapState } from 'vuex';
export default {
    mixins: [formRulesMixin],

    data: function () {
        return {
            loading: false,
            valid: true,
            structuredNumber: null
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData'])
    },

    methods: {
        async handleSubmit() {
            this.loading = true;
            try {
                await updateStructuredNumberSettingsRequest({
                    structuredNumber: this.currentUserData.settings.structuredNumber
                });

                this.$notify({
                    title: this.$t('settings.succesfuly_updated'),
                    text: this.$t('settings.succesfuly_updated'),
                    type: 'success'
                });
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
