
import Vue, { PropOptions } from 'vue';
// --- Components ---
import NumberField from '../FormComponents/NumberField.vue';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin';

export default Vue.extend({
    name: 'CounterNumberField',
    components: {
        NumberField
    },
    mixins: [formRulesMixin],
    props: {
        value: {
            default: null,
            type: [Number, null]
        } as PropOptions<number | null>,
        name: {
            default: '',
            type: String,
            required: true
        } as PropOptions<string>,
        i18nLabel: {
            default: '',
            type: String,
            required: true
        } as PropOptions<string>,
        counterPreviewText: {
            default: '',
            type: String,
            required: true
        } as PropOptions<string>
    },
    data() {
        return {
            isCollapsed: true as boolean
        };
    },
    computed: {
        counter: {
            get(): number | null {
                return this.value;
            },
            set(newCounterValue: number): void {
                this.$emit('input', newCounterValue);
            }
        }
    },
    methods: {
        setPreviewIsVisible(): void {
            this.isCollapsed = false;
        },
        setPreviewIsCollapsed(): void {
            this.isCollapsed = true;
        }
    }
});
