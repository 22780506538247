<template>
    <div class="horiontal-scroll-mobile">
        <v-row>
            <v-col v-if="!hasCanceledSubscription" cols>
                <v-card class="Widget pa-5 fill-height" outlined>
                    <data-card-multiple
                        :data="subscriptionInfo.subscription.current_period_end | formatDateUnix"
                        :data-text="$t('subscriptions.next_payment_at')"
                        avatar-color="red lighten-5"
                        icon-color="red lighten-1"
                        :disable-numeric-format="true"
                    />
                </v-card>
            </v-col>
            <v-col cols>
                <v-card class="Widget pa-5 fill-height" outlined>
                    <data-card-multiple
                        :data="$t(`general.${subscriptionInfo.subscription.plan.interval}`)"
                        :data-text="'Interval'"
                        avatar-color="red lighten-5"
                        icon-color="red lighten-1"
                        :disable-numeric-format="true"
                    />
                </v-card>
            </v-col>
            <v-col cols>
                <v-card class="Widget pa-5 fill-height" outlined>
                    <data-card-multiple
                        :data="subscriptionInfo.subscription.plan.amount / 100"
                        leading="€"
                        :trailing="
                            subscriptionInfo.subscription.plan.amount !== 0
                                ? '/' + $t(`general.${subscriptionInfo.subscription.plan.interval}`)
                                : ''
                        "
                        :data-text="$t('general.price')"
                        avatar-color="red lighten-5"
                        icon-color="red lighten-1"
                        :disable-numeric-format="true"
                    />
                </v-card>
            </v-col>
            <template v-if="!hasCanceledSubscription">
                <v-col cols>
                    <v-card
                        class="Widget pa-5 text-center fill-height"
                        style="display: flex; flex-direction: column; justify-content: center"
                        outlined
                    >
                        <v-btn color="error" @click="handleCancelSubscription"> Abonnement stopzetten </v-btn>
                    </v-card>
                </v-col>
            </template>
            <v-col v-if="hasCanceledSubscription" cols>
                <v-card
                    class="Widget pa-5 fill-height"
                    style="display: flex; justify-content: center; align-items: center"
                    outlined
                >
                    <submit-button @click="handleUndoCancelSubscription"> Abonnement hervatten </submit-button>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import DataCardMultiple from '@/components/DataCardMultiple';
export default {
    components: {
        DataCardMultiple
    },

    props: ['subscriptionInfo'],

    computed: {
        hasCanceledSubscription() {
            if (!this.subscriptionInfo.subscription) {
                return false;
            }

            return this.subscriptionInfo.subscription.cancel_at;
        }
    },

    methods: {
        handleUndoCancelSubscription() {
            this.$emit('click-undo-cancel-subscription');
        },
        handleCancelSubscription() {
            this.$emit('click-cancel-subscription');
        }
    }
};
</script>
