import { intervalTypeRecurringStripe, productCategory, subscriptionTypes } from '../interfaces';
import { Plan } from './Plan';
import { Price } from './Price';

export class PlanPriceInfo {
    private _plan: Plan;
    private _price: Price;

    constructor(plan: any, price: any) {
        this._plan = plan;
        this._price = price;
    }

    get id() {
        return this.plan.id;
    }

    get interval() {
        return this.plan.recurring ? this.plan.recurring.interval : 'year';
    }

    isIntervalPlan(interval: intervalTypeRecurringStripe): boolean {
        return this.interval === interval;
    }

    get subscriptionType(): subscriptionTypes {
        return this.price.metadata.type;
    }

    get subscriptionCategory(): productCategory {
        return this.price.metadata.category;
    }

    isCategoryTier(subscriptionType: productCategory): boolean {
        return this.subscriptionCategory === subscriptionType;
    }

    isYearly(): boolean {
        return this.plan.isYearly();
    }

    isMonthly(): boolean {
        return this.plan.isMonthly();
    }

    isFreeTier() {
        return this.price.isFreeTier();
    }

    isProTier() {
        return this.price.isProTier();
    }

    isLiteTier() {
        return this.price.isLiteTier();
    }

    isRecurring() {
        return this.plan.isRecurringPlan();
    }

    isManualPlan() {
        return this.plan.isManualPlan();
    }

    public get price(): Price {
        return this._price;
    }
    public set price(value: Price) {
        this._price = value;
    }

    public get plan(): Plan {
        return this._plan;
    }
    public set plan(value: Plan) {
        this._plan = value;
    }
}
