<template>
    <div class="v-stepper-wrapper">
        <v-stepper-step editable :complete="stepper > 1" step="1">
            <div class="TooltipTextWrapper">
                {{ $t('subscription.choose_plan') }}
            </div>
        </v-stepper-step>
        <v-stepper-content step="1" :class="{ 'stepper-content-active': stepper == 1 }">
            <v-row>
                <v-col v-for="productTier of productTiers" :key="productTier">
                    <subscription-tier-select :product-tier="productTier" @choose="chooseTier" />
                </v-col>
            </v-row>
        </v-stepper-content>
    </div>
</template>

<script>
import SubscriptionTierSelect from './SubscriptionTierSelect.vue';

export default {
    components: {
        SubscriptionTierSelect
    },
    props: ['stepper', 'productTiers', 'value'],

    methods: {
        chooseTier(tier) {
            this.$emit('input', tier);
            this.$emit('next');
        }
    }
};
</script>
