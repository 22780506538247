import { IProductMetadata } from '../product.repository.interface';
export class Price {
    private _id: string;
    private _metadata: IProductMetadata;

    constructor(id: string, metadata: IProductMetadata) {
        this._id = id;
        this._metadata = metadata;
    }

    isFreeTier() {
        return this._metadata.type === 'free_tier';
    }

    isProTier() {
        return this._metadata.type === 'pro_tier';
    }

    isLiteTier() {
        return this._metadata.type === 'lite_tier';
    }

    public get metadata(): IProductMetadata {
        return this._metadata;
    }
    public set metadata(value: IProductMetadata) {
        this._metadata = value;
    }
    public get id(): string {
        return this._id;
    }
    public set id(value: string) {
        this._id = value;
    }
}
